<template>
  <div>
    <h1 class="text-18 text-gray-700 font-semibold">
      CredPal MarketPlace
    </h1>
    <marketplace-category />
  </div>
</template>

<script>
export default {
  name: 'MarketPlace',
};
</script>
  